body {
  font-family: 'Archivo', sans-serif;
  margin: 0;
  overflow: hidden;
  display: flex; 
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(-45deg, #9945FF, #14F195);
}

.container {
  width: 60vw;
  height: 60vh;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F3F3F3;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}